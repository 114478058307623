import * as React from "react";
import "../components/sky.css";

const Sky = () => (
  <div id='sky'>
    <span>
      <s></s>
      <s></s>
      <s></s>
      <s></s>
      <s></s>
    </span>
  </div>
);

export default Sky;
