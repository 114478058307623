import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About`}</h1>
    <h5>{`In summary, I am a passionate and multi-faceted software engineer, with a strong foundation in both the logical and creative sides of web development. My interests extend beyond my current responsibilities, and I am continually exploring new fields of knowledge. I am committed to using my skills to create innovative solutions that improve people's lives, and I look forward to the challenges and opportunities that lie ahead.`}</h5>
    <h6>{`For more details, please check out the full version on my blog `}<a href="https://blog.mahmoudamr.space/about" target='_blank'>{`Here`}</a></h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      