import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a href="mailto:mahmoud.mansour.amr.mm@gmail.com">{`Hi`}</a>{` or find me on other platforms: `}<a href="https://www.linkedin.com/in/mahmoud-amrr/" target='_blank'>{`Linkedin`}</a>{` & `}<a href="https://twitter.com/mah_amrr" target='_blank'>{`Twitter`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      