import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Side-Projects`}</h2>
    <ProjectCard title="simple pyhton microservices" link="https://github.com/mahmoudamrr/python-microservices-side-project" bg="linear-gradient(315deg, #89d4cf 0%, #6e45e1 74%)" mdxType="ProjectCard">
    </ProjectCard>
    <ProjectCard title="Setting  up a yarn Workspace" link="" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      