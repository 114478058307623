/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui";

const Footer = () => {
  // const [colorMode, setColorMode] = useColorMode();
  // const isDark = colorMode === `dark`;
  // const toggleColorMode = (e: any) => {
  //   setColorMode(isDark ? `light` : `dark`);
  // };

  return (
    <Box as="footer" variant="footer">
      {/* <button
        sx={{
          variant: `buttons.toggle`,
          fontWeight: `semibold`,
          display: `block`,
          mx: `auto`,
          mb: 3,
        }}
        onClick={toggleColorMode}
        type="button"
        aria-label="Toggle dark mode"
      >
        {isDark ? `Light` : `Dark`}
      </button> */}
      Mahmoud Amr &copy; {new Date().getFullYear()}.
    </Box>
  );
};

export default Footer;
